import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withPrefix } from "gatsby"
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { P, SubCondensed } from "components/Typography"

import Dialog from "components/Layouts/Dialog"
import { FlexParent } from "components/Layouts/Utils"
import Button, { ButtonLink } from "components/Button"


const StyledBlock = styled.button`
    padding: 1rem;
    text-align: left;
    cursor: pointer;
    width: calc(33.33% - 1rem);
    border: 1px solid var(--lilacLowOpacity);
    transition: 0.3s ease-out background-color;

    &:hover {
        background-color:var(--bgLight);
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`

const Figure = styled.figure`
    width: 100%;
    height: 0;
    margin-top: 0.5rem;
    padding-bottom: 100%;
    position: relative;
    background-color: var(--bgLight);
`

const FullImage = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;
    max-height: calc(100vh - 300px);
`

const PreviewImage = styled.img`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;left:0;
    object-fit: contain;
    object-position: center center;
`

const BlockContainer = styled.section`
    width: 100%;
`

const DesktopButtons = styled.div`
    @media(max-width: 600px) {
        display: none;
    }
`
const MobileButtons = styled.div`
    display: none;

    @media(max-width: 600px) {
        margin-top: 2rem;
        display: block;
        width: 100%;
    }
`

const Block = ({
    title, credit, link, img
}) => {
    const [showDialog, setShowDialog] = useState(false)
    const [copied, setCopied] = useState(false)
    const [location, setLocation] = useState(null)

    useEffect(() => {
        setLocation(window.location.href)
    }, [])

    const open = () => {
        setShowDialog(true)
    }

    const close = () => {
        setShowDialog(false)
    }

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false)
            }, 2000)
        }
    }, [copied])

    return (
        <>
            <StyledBlock
                onClick={open}
            >
                <BlockContainer>
                    <P style={{ marginBottom: 0 }}>{title ? title : "\n"}</P>
                    <SubCondensed
                        style={{
                            marginBottom: "1rem"
                        }}>{credit ? `By: ${credit}` : " "} </SubCondensed>
                    <Figure>
                        <PreviewImage
                            loading="lazy"
                            src={withPrefix(img.url)}
                            alt={img.alt}
                        />
                    </Figure>
                </BlockContainer>

                <Dialog showDialog={showDialog} open={open} close={close}>
                    <FullImage loading="lazy"
                        src={withPrefix(img.url)}
                        alt={img.alt} />

                    <FlexParent
                        style={{
                            padding: "1rem"
                        }}
                        justifyContent="space-between"
                    >
                        <div>
                            <P style={{ marginBottom: 0 }}>{title}</P>
                            {credit && credit !== "" && <SubCondensed
                                style={{
                                }}>By: {credit}</SubCondensed>}
                        </div>

                        <DesktopButtons>
                            <ButtonLink
                                parentStyle={{ marginRight: "1rem" }}
                                href={link}
                                bgColor='var(--background)'
                                outlineColor="var(--lilac)"

                            >View Original</ButtonLink>

                            <CopyToClipboard text={location}
                                onCopy={() => setCopied(true)}>
                                <Button
                                    bgColor='var(--background)'
                                    outlineColor="var(--lilac)"
                                >
                                    {copied ? "Copied" : "Copy"}
                                </Button>
                            </CopyToClipboard>
                        </DesktopButtons>

                        <MobileButtons>
                            <ButtonLink
                                parentStyle={{
                                    margin: "0",
                                    marginBottom: "1rem",
                                    width: "100%", textAlign: "center"
                                }}
                                width="100%"
                                href={link}
                                bgColor='white'
                                color="var(--dark)"

                            >View Original</ButtonLink>

                            <CopyToClipboard text={location}
                                onCopy={() => setCopied(true)}>
                                <Button
                                    width="100%"
                                    bgColor='white'
                                    color="var(--dark)"
                                >
                                    {copied ? "Copied" : "Copy"}
                                </Button>
                            </CopyToClipboard>
                        </MobileButtons>
                    </FlexParent>
                </Dialog>
            </StyledBlock>

        </>
    )
}

export default Block