import React, { useState, useEffect } from "react"
import styled from "styled-components";

import Layout from "components/Blog/Layout"
import FeaturedHeader from "components/Blog/FeaturedHeader"

import { MedMaxWidth } from "components/Layouts/MaxWidth"
import { P } from "components/Typography"
import { ButtonLink } from "components/Button"

import ContentBlocks from "./ContentBlocks"

const StyledFooter = styled.section`
    padding: 4rem 1rem;
    text-align: center;

    @media (max-width:750px) {
        text-align: left;
        padding-left: 0;
        padding-right: 0;
    }
`

const HASHTAG = "#SKYWEAVERCREATORS"


const urlRegex = /(https?:\/\/[^ ]*)/;
const filterTweetText = (text) => {
    const lineBreaks = text.split("\n")

    let title = false
    let credit = false
    let url = false

    if (lineBreaks && lineBreaks.length >= 0) {
        console.log(lineBreaks, "L")
        title = lineBreaks.filter(t => t.includes("Title:"))[0]?.split("Title: ") || null
        credit = lineBreaks.filter(t => t.includes("Credit:"))[0]?.split("Credit: ") || null
    }


    url = text.match(urlRegex)[1];

    return [title && title.length > 0 && title[1], credit && credit.length > 0 && credit[1], url]
}

const CommunityHallOfFame = ({
    title,
    content,
    tweets,
    footer
}) => {
    const [processedTweets, setTweets] = useState()

    useEffect(() => {
        const { data, media } = content
        let tweetsToFilter = []

        data.map(tweet => {
            if (tweet.text.toUpperCase().indexOf(HASHTAG) > -1) {

                const mediaTarget = tweet.attachments && tweet.attachments.media_keys && tweet.attachments.media_keys[0]

                if (mediaTarget) {
                    const findMedia = media && media && media.find(m => m.media_key === mediaTarget)

                    const filteredText = filterTweetText(tweet.text)

                    if (findMedia && filteredText[0]) {
                        tweet.media = findMedia
                        tweet.title = filteredText[0]
                        tweet.creator = filteredText[1]
                        tweet.link = filteredText[2]
                        tweetsToFilter.push(tweet)
                    }
                }
            }

            return null
        })

        setTweets(tweetsToFilter)
    }, [content])

    return (
        <Layout>
            <section>
                <FeaturedHeader>{title}</FeaturedHeader>
            </section>

            {processedTweets && <ContentBlocks blocks={processedTweets} />}

            <StyledFooter>
                <MedMaxWidth>
                    <FeaturedHeader>{footer.title}</FeaturedHeader>

                    <P dangerouslySetInnerHTML={{ __html: footer.subtitleHTML }} />

                    {footer.buttonLink && <ButtonLink width='14rem' href={footer.buttonLink} color={"black"}>
                        {footer.buttonTitle}
                    </ButtonLink>}
                </MedMaxWidth>
            </StyledFooter>
        </Layout>
    )
}

export default CommunityHallOfFame