import React from "react"

import { MainContentMaxWidth } from "components/Layouts/MaxWidth"
import { FlexParent } from "components/Layouts/Utils"

import Block from "./Block"


const ContentBlocks = ({ blocks }) => {
    return (
        <MainContentMaxWidth>
            <FlexParent
                style={{
                    gap: "1.3rem",
                    alignItems: "flex-start",
                    justifyContent: "flex-start"


                }}
            >
                {blocks.map((block, i) => <Block
                    title={block.title}
                    credit={block.creator}
                    link={block.link}
                    img={{
                        url: block.media.url,
                        alt: block.text
                    }}
                    key={i} />)}
            </FlexParent>
        </MainContentMaxWidth>
    )
}

export default ContentBlocks