const data = {
    title: "Community Hall of Fame",
    // this is mock data and will need to be updated
    // ideally connected to a CMS where the team 
    // can dynamically update latest content
    content: [
        {
            title: "Artwork Title",
            credit: "@fan_username",
            link: "https://twitter.com",
            img: {
                url: "/images/community/community-hall-of-fame/1.jpg",
                alt: "Artwork Title"
            }
        },
        {
            title: "Artwork Title",
            credit: "@fan_username",
            link: "https://twitter.com",
            img: {
                url: "/images/community/community-hall-of-fame/2.jpg",
                alt: "Artwork Title"
            }
        },
        {
            title: "Artwork Title",
            credit: "@fan_username",
            link: "https://twitter.com",
            img: {
                url: "/images/community/community-hall-of-fame/3.jpg",
                alt: "Artwork Title"
            }
        }
        , {
            title: "Artwork Title",
            credit: "@fan_username",
            link: "https://twitter.com",
            img: {
                url: "/images/community/community-hall-of-fame/4.jpg",
                alt: "Artwork Title"
            }
        },
        {
            title: "Artwork Title",
            credit: "@fan_username",
            link: "https://twitter.com",
            img: {
                url: "/images/community/community-hall-of-fame/5.jpg",
                alt: "Artwork Title"
            }
        }, {
            title: "Artwork Title",
            credit: "@fan_username",
            link: "https://twitter.com",
            img: {
                url: "/images/community/community-hall-of-fame/6.jpg",
                alt: "Artwork Title"
            }
        }
    ],

    footer: {
        title: "SHARE YOUR CREATIONS",
        subtitleHTML: `Share your Skyweaver creations on Instagram, Twitter, or Facebook with the hashtag  <span class="color-purple">#SkyweaverCreators</span> to be featured in our Community Hall of Fame!`,
        // buttonTitle: "Submit Artwork",
        // buttonLink: "mailto:hello@skyweaver.net"
    }
}

export default data