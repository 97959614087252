import React, { useState, useEffect } from "react";

import "css/main.css";
import "css/fonts.css";

import CommunityHallOfFame from "components/Community/CommunityHallOfFame"
import Seo from "components/SeoHead";

// title, content, footer
import data from "data/community/community-hall-of-fame-data"
import { tweets } from "data/social/creator-hall-of-fame-tweets"

// import * as content from "data/snapshot/twitter-hall-of-fame-snapshot.json"

function CommunityHallOfFamePage() {
    const [content, setContent] = useState(null)

    useEffect(() => {
        const getPosts = async () => {

            const request = await fetch(`https://sky-utils.vercel.app/api/read-twitter`)
            const json = await request.json()

            if (json) setContent(json)

        }

        getPosts()
    }, [])


    return (
        <>
            <Seo />
            {content && <CommunityHallOfFame
                title={data.title}
                tweets={tweets}
                content={content}
                footer={data.footer}
            />}
        </>
    )
}

export default CommunityHallOfFamePage